
/**
 * Single device view schematin main project
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { MainProjectSection } from "@/model/MainProject/MainProjectSection";
import { Component, Prop, Vue } from "vue-property-decorator";

import SchematicDisplayDevice from "./SchematicDisplayDevice.vue";
import SchematicMeasureDevice from "./SchematicMeasureDevice.vue";
import SchematicCameraDevice from "./SchematicCameraDevice.vue";
import SchematicInfoDevice from "./SchematicInfoDevice.vue";
import { mapGetters } from "vuex";
import SchematicRadarData from "./SchematicRadarData.vue";
import RibbonItem from "@/components/shared/RibbonItem.vue";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "MainprojectSingleDeviceView",
  components: {
    SchematicDisplayDevice,
    SchematicMeasureDevice,
    SchematicCameraDevice,
    SchematicInfoDevice,
    SchematicRadarData,
    RibbonItem
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class MainprojectSingleDeviceView extends Vue {
  @Prop({ type: Object, required: true }) sec!: MainProjectSection;
  readonly getUserAccess!: UserAccess;

  get deviceType() {
    switch (this.sec.type.name) {
      case "display":
        return "SchematicDisplayDevice";
      case "measure":
        return "SchematicMeasureDevice";
      case "camera":
        return "SchematicCameraDevice";
      default:
        return "SchematicInfoDevice";
    }
  }

  /*----------  Methods  ----------*/

  loadSectionBackgroundImage(name: string) {
    if (name === "none.svg" || !name) return;
    return require(`@/assets/images/projects/RZP_BG/options/png/lines-${name}`);
  }
}
