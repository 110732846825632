
/**
 * Schematic view for the Information view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins } from "vue-property-decorator";
import SchematicDeviceDetails from "./MainProjectSchematicDeviceDetails.vue";

import DeviceStatus from "@/mixins/main/Device";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";

@Component({
  name: "SchematicInfoDevice",
  components: {
    SchematicDeviceDetails
  }
})
export default class SchematicInfoDevice extends Mixins(DeviceStatus, KmFormatted) {}
