
/**
 * Display device view for the schematic
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { InformationBubbleData } from "@/interface/RadarSection";
import { MainProjectSection } from "@/model/MainProject/MainProjectSection";
import ModelUser from "@/model/ModelUser";
import UserAccess from "@/model/User/UserAccess";
import { TrafficVolumeData } from "@/services/evaluatorService";
import { formatNumberInt } from "@/utils/FormatNumberInt";
import { toKM } from "@/utils/TransformToKM";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import MeasureInformationBubble from "../project/SingleProject/Schematic/SinlgeViewTypes/MeasureInformationBubble.vue";

@Component({
  name: "SchematicRadarData",
  computed: {
    ...mapGetters(["getUserAccess", "getCurrentUser"]),
    ...mapGetters("Evaluator", {
      GET_SECTION_TRAFFIC_VOLUME_MAIN_PROJECT: "GET_SECTION_TRAFFIC_VOLUME_MAIN_PROJECT"
    })
  },
  components: {
    MeasureInformationBubble
  }
})
export default class SchematicRadarData extends Vue {
  /*----------  Vuex  ----------*/
  // eslint-disable-next-line no-unused-vars
  GET_SECTION_TRAFFIC_VOLUME_MAIN_PROJECT!: (id: number, PID: number) => TrafficVolumeData;
  readonly getCurrentUser!: ModelUser;

  formatNumberInt = formatNumberInt;
  readonly getUserAccess!: UserAccess;
  @Prop({ type: Object, required: true }) sec!: MainProjectSection;

  toKM = toKM;

  get getTrafficJamStateClass() {
    let avgSpeed = this.sec.speedAverage;
    let occupancy = this.sec.occupancy;

    let lowSpeedThreshold = 35;
    let midSpeedThreshold = 50;
    let occupancyThreshold = 17;

    if (this.sec.project.congestionWarning) {
      lowSpeedThreshold = this.sec.project.cwuParams.low;
      midSpeedThreshold =
        this.sec.project.mq3Aq32SW ||
        this.sec.project.mq4Aq42SWDEGES ||
        this.sec.project.mq4Aq42SW ||
        this.sec.project.mq5Aq52SW ||
        this.sec.project.mq1Aq12SW
          ? this.sec.project.cwuParams.high
          : this.sec.project.cwuParams.mid;
      occupancyThreshold = this.sec.project.cwuParams.occupancyLimit;
    }

    if (this.sec.project.roadzipper) {
      lowSpeedThreshold = 30;
      midSpeedThreshold = 55;
    }

    if (avgSpeed === 0) return occupancy < occupancyThreshold ? "traffic-jam-free" : "traffic-jam";
    return avgSpeed < lowSpeedThreshold && avgSpeed > 0
      ? "traffic-jam"
      : avgSpeed >= lowSpeedThreshold && avgSpeed < midSpeedThreshold
      ? "traffic-jam-risk"
      : "traffic-jam-free";
  }
  get mainProjectRadarData(): InformationBubbleData {
    return {
      getTrafficJamStateClass: this.getTrafficJamStateClass,
      averageSpeed: this.sec.speed,
      occupancy: this.sec.occupancy,
      laneName: this.sec.laneName,
      totalVolumePerHour: formatNumberInt(
        this.GET_SECTION_TRAFFIC_VOLUME_MAIN_PROJECT(this.sec.id, this.sec.project.id).totalVolumePerHour,
        this.getCurrentUser.getLocale
      )
    };
  }
}
