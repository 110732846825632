
/**
 * Single device type display view
 * for the schematic vie in the main project
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import SingleDeviceHeader from "@/components/project/SingleProject/Schematic/SinlgeViewTypes/SingleDeviceHeader.vue";
import UserAccess from "@/model/User/UserAccess";
import { isTheScreenOff } from "@/utils/DeviceHelper";
import { DEVICE_STATUS } from "@/model/ModelSection";
import { WEP_MOCK_URL } from "@/shared/keycloak";

@Component({
  name: "SchematicDisplayDevice",
  components: {
    SingleDeviceHeader
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class SchematicDeviceDetails extends Vue {
  /*----------  Props  ----------*/
  @Prop({ type: Number, default: 0 }) projectId!: number;
  @Prop({ type: String, default: "" }) projectName!: string;

  @Prop({ type: String, default: "" }) title!: string;
  @Prop({ type: String, default: "" }) description!: string;
  @Prop({ type: Boolean, default: true }) showTitle!: boolean;

  @Prop({ type: String, default: "", required: true }) kilometer!: string;
  @Prop({ type: [String], default: "", required: true }) haveDeviceAttach!: number;

  @Prop({ type: Boolean, default: false }) radio!: boolean;
  @Prop({ type: Boolean, default: false }) showCbRadioIcon!: boolean;
  @Prop({ type: Boolean, default: false }) isSupport!: boolean;
  @Prop({ type: Number, default : 1}) batteryNumber!: number;
  @Prop({ type: String, default: "", required: true }) batteryClass!: string;
  @Prop({ default: "", type: String }) netType!: string;
  @Prop({ default: "", type: String }) deviceNetworkConnectionQualityASU!: string;

  @Prop({ type: Boolean, default: false }) isCWU!: boolean;

  @Prop({ type: String, default: "" }) statusClass!: string;
  @Prop({ type: Number, default: 48 }) prewarningWith!: string;
  @Prop({ type: Boolean, default: false }) prewarnmingActive!: boolean;
  @Prop({ type: Boolean, default: false }) deviceOnline!: boolean;

  @Prop({ type: String, default: "" }) imageSrc!: string;
  @Prop({ type: String, default: "48px" }) displayImageWith!: string;
  @Prop({ type: String, default: "48px" }) displayImageHeight!: string;
  @Prop({ type: String, default: "48px" }) displayImageAltText!: string;
  @Prop({ type: String, default: "" }) deviceStatus!: DEVICE_STATUS;

  /*=====  Vuex Getter and actions  ======*/
  readonly getUserAccess!: UserAccess;

  /*=====  Vue getter  ======*/

  get getStatus(): string {
    if (this.isSupport) return "SUPPORT";
    return this.deviceStatus;
  }
  get isOff() {
    const status = isTheScreenOff(this.batteryClass, this.deviceStatus, this.deviceOnline);
    return status;
  }
  get displayOffSrc() {
    return `${WEP_MOCK_URL}/img/${this.displayImageWith.replace("px", "")}x${this.displayImageHeight.replace(
      "px",
      ""
    )}/noPicture.jpg`;
  }

  /*=====  Vue   ======*/
  HandleImageError(event: Event | any) {
    const src = event.target?.src;
    if (src.includes(".gif")) {
      event.target.src = src.replace(".gif", ".bmp");
    }
  }
  HandleOpenInformationModal() {
    this.$emit("open-info-modal");
  }
}
