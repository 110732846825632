
/**
 * All the tabs for the main project view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import VueI18n from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";
import UserTabView from "@/components/project/SingleProject/Users/UserTabView.vue";
import MainProjectMetaDataTab from "@/components/mainProject/MainProjectMetaDataTab.vue";
import MainProjectDeviceListTab from "@/components/mainProject/MainProjectDeviceListTab.vue";
import MainProjectSectionListTab from "@/components/mainProject/MainProjectSectionListTab.vue";
import MainProjectBatteryListTab from "@/components/mainProject/MainProjectBatteryListTab.vue";
import { mapGetters } from "vuex";
import UserAccess from "@/model/User/UserAccess";
@Component({
  name: "MainProjectTabs",
  components: {
    MainProjectMetaDataTab,
    UserTabView,
    MainProjectSectionListTab,
    MainProjectDeviceListTab,
    MainProjectBatteryListTab
  },
  computed: {
    ...mapGetters(["getUserAccess"])
  }
})
export default class MainProjectTabs extends Vue {
  $t!: VueI18n["t"];
  /*----------  Props  ----------*/

  readonly getUserAccess!: UserAccess;
}
