
/**
 * Battery list tab view,
 * the table of Battery the current project,
 * all formatting and display information for the Battery list.
 *
 *
 * @author Rami Aqqad <Ibomade GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import Project from "@/model/Project";
import { BatteryDevice } from "@/model/SingleDeviceModal";

import confirmModal from "@/mixins/confirmModal/confirmModal";
import UserAccess from "@/model/User/UserAccess";
import { MainProjectSection } from "@/model/MainProject/MainProjectSection";

interface Status {
  class: string;
  msg: string;
}
@Component({
  name: "MainProjectBatteryListTab",
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters({
      getAllDevices: "getAllDevices",
      getAllBatteryDevicesInProject: "getAllBatteryDevicesInProject",
      getAllBatteryDevices: "getAllBatteryDevices",
      getDeviceById: "getDeviceById"
    }),
    ...mapGetters("projects", {
      CurrentProject: "GetCurrentProject",
      GetActiveSubProjectsList: "GetActiveSubProjectsList"
    }),
    ...mapGetters("MainProject", {
      GETTER_MAIN_DIRECTIONS: "GETTER_MAIN_DIRECTIONS"
    })
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd", "updateDevice", "updateGpsPositionDevice"]),
    ...mapActions("projects", {
      GetAllProjectsFromBackEnd: "GetAllProjectsFromBackEnd"
    })
  }
})
export default class MainProjectBatteryListTab extends Mixins(confirmModal) {
  /******Props *****/
  listOfDevices: Array<BatteryDevice> = [];
  readonly GETTER_MAIN_DIRECTIONS!: Map<String, Array<MainProjectSection>>;

  /******Vuex *****/
  readonly getUserAccess!: UserAccess;
  readonly getDeviceById!: any;
  readonly CurrentProject!: Project;
  readonly getAllDevices!: Array<BatteryDevice>;
  readonly getAllBatteryDevices!: Array<BatteryDevice>;
  getAllBatteryDevicesInProject!: Function;
  private getAllDevicesFromBackEnd!: Function;
  updateDevice!: Function;
  updateGpsPositionDevice!: Function;
  private GetAllProjectsFromBackEnd!: Function;
  private GetActiveSubProjectsList!: Function;

  /******localState *****/
  labelsForDeviceList: Array<string> = ["id", "serialNumber", "emnifyIccId", "gps", "gpsLastUpdated", "actions"];
  updateStatus: null | Status = null;
  /*----------  Vue watchers  ----------*/

  /*----------  Getters  ----------*/

  async created() {
    await this.GetAllProjectsFromBackEnd();
    await this.getAllDevicesFromBackEnd();
    await this.getAllBatteryDevicesInProject(this.CurrentProject.id);
  }

  getAllBatteryDevicesInMainProject(mainProjectId: String, currentDirection: String) {
    let activeSubProjects = this.GetActiveSubProjectsList(mainProjectId);

    let mainProjectBattery: any[] = [];
    //iterate through active subprojects
    activeSubProjects.forEach((subProject: any) => {
      if (subProject.directionNameEnd === currentDirection) {
        /**if the project type is a speedfunnel - iterate through its sub subprojects and add these batteries
        to the mainProjectBattery list **/
        if (subProject.type.name === "speedfunnel" || subProject.type.name === "congestion_warning_cascaded") {
          let subSubProjects = this.GetActiveSubProjectsList(subProject.id);
          subSubProjects.forEach((subSubProject: any) => {
            if (subSubProject.directionNameEnd === currentDirection) {
              mainProjectBattery = mainProjectBattery.concat(this.getAllBatteryDevicesInProject(subSubProject.id));
            }
          });
        } else {
          //iterate through regular subprojects and add the batteries to the mainProjectBattery list
          mainProjectBattery = mainProjectBattery.concat(this.getAllBatteryDevicesInProject(subProject.id));
        }
      }
    });
    return mainProjectBattery;
  }

  emitUpdateStatus(status: object) {
    this.$emit("updateStatus", status);
  }

  translateTableHeaderLabel(label: string) {
    return this.$t(`inventory.tableLabels.${label}`);
  }

  handleCallGpsForAllBatteriesInThisProject() {
    this.updateStatus = null;
    this.$emit("updateStatus", null);

    let activeSubProjects = this.GetActiveSubProjectsList(this.CurrentProject.id);
    let updateBatteryInProjectStatus: any = null;
    this.confirm(
      (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.confirmCallGpsAllBatteries", {}),
      async () => {
        try {
          activeSubProjects.forEach(async (subProject: any) => {
            let batteriesInThisProject = this.getAllBatteryDevicesInProject(subProject.id);
            // iterate through sub subProjects if subProject type is a speedfunnel and add these to batteriesInThisProject list
            if (subProject.type.name === "speedfunnel" || subProject.type.name === "congestion_warning_cascaded") {
              let subSubProjects = this.GetActiveSubProjectsList(subProject.id);
              subSubProjects.forEach((subSubProject: any) => {
                batteriesInThisProject = batteriesInThisProject.concat(
                  this.getAllBatteryDevicesInProject(subSubProject.id)
                );
              });
              // for regular suprojects
            } else {
              batteriesInThisProject = this.getAllBatteryDevicesInProject(subProject.id);
            }

            for (const battery of batteriesInThisProject) {
              updateBatteryInProjectStatus = await new Promise((resolve, reject) => {
                this.updateGpsPositionDevice(battery.serialNumber)
                  .then((response: any) => resolve(response))
                  .catch((error: any) => reject(error));
              });

              if (
                updateBatteryInProjectStatus.status === 202 &&
                this.checkGPSResponseAvailability(updateBatteryInProjectStatus)
              ) {
                this.$emit("updateStatus", {
                  class: "success",
                  msg:
                    (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSSuccess") +
                    "(" +
                    this.translateTableHeaderLabel("serialNumber") +
                    ": " +
                    updateBatteryInProjectStatus.data.serialNumber +
                    ")"
                });
              } else {
                this.$emit("updateStatus", {
                  class: "danger",
                  msg:
                    (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSFaild") +
                    " (" +
                    this.translateTableHeaderLabel("serialNumber") +
                    ": " +
                    updateBatteryInProjectStatus.data.serialNumber +
                    " / SMS status: " +
                    updateBatteryInProjectStatus.data.gpsSmsStatusDescription +
                    " ) "
                });
              }
            }
          });
        } catch (error) {
          this.$emit("updateStatus", {
            class: "danger",
            msg: error
          });
        }
      }
    );
  }

  checkGPSResponseAvailability(response: any) {
    return (
      (response.data.latitude !== 0 || response.data.longitude !== 0) &&
      (response.data.gpsSmsStatusDescription === "DELIVERY ATTEMPT PENDING" ||
        response.data.gpsSmsStatusDescription === "DELIVERED")
    );
  }
  callGps(serialNumber: String) {
    this.updateStatus = null;
    this.$emit("updateStatus", null);
    this.confirm(
      (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.confirmCallGps", {
        id: serialNumber
      }),
      async () => {
        try {
          let updateBatteryInProjectStatus = await this.updateGpsPositionDevice(serialNumber);
          if (
            updateBatteryInProjectStatus.status === 202 &&
            this.checkGPSResponseAvailability(updateBatteryInProjectStatus)
          ) {
            this.$emit("updateStatus", {
              class: "success",
              msg:
                (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSSuccess") +
                "(" +
                this.translateTableHeaderLabel("serialNumber") +
                ": " +
                updateBatteryInProjectStatus.data.serialNumber +
                ")"
            });
          } else {
            this.$emit("updateStatus", {
              class: "danger",
              msg:
                (this as any).$t("project.singleProject.battery.modalAddBatteryToProject.callGPSFaild") +
                " (" +
                this.translateTableHeaderLabel("serialNumber") +
                ": " +
                updateBatteryInProjectStatus.data.serialNumber +
                " / SMS status: " +
                updateBatteryInProjectStatus.data.gpsSmsStatusDescription +
                " ) "
            });
          }
        } catch (error) {
          this.$emit("updateStatus", {
            class: "danger",
            msg: error
          });
        }
      }
    );
  }
}
