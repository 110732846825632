
/**
 * Display device view for the schematic
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { MainProjectSectionDeviceView, sectionDirectionDevice } from "@/model/MainProject/MainProjectSection";
import { Component, Mixins } from "vue-property-decorator";
import SchematicDeviceDetails from "./MainProjectSchematicDeviceDetails.vue";
import { WEP_MOCK_URL } from "@/shared/keycloak";
import DeviceStatus from "@/mixins/main/Device";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import { getConnectionStrengthImageName } from "@/utils/DeviceHelper";

@Component({
  name: "SchematicDisplayDevice",
  components: {
    SchematicDeviceDetails
  }
})
export default class SchematicDisplayDevice extends Mixins(DeviceStatus, KmFormatted) {
  get device(): MainProjectSectionDeviceView {
    const direction: sectionDirectionDevice = {
      deviceOne: this.sec.direction === "DEFAULT" ? this.sec.deviceOne : this.sec.deviceTwo,
      deviceTwo: this.sec.direction === "DEFAULT" ? this.sec.deviceTwo : this.sec.deviceOne
    };
    return direction[this.devicePosition];
  }

  get isDeviceOnWaitingState(): boolean {
    return this.device.status === "WAITING";
  }

  /*----------  if the device is on WAITING state check the target picture if is stau or Stgefahr prewarning lamp should be on   ----------*/
  get getPrewarningState(): boolean {
    let desiredPrewarningActive = this.device.prewarning;
    if (this.isDeviceOnWaitingState) {
      desiredPrewarningActive = this.isPictureStauOrStgefahr(this.device.targetPicture);
    }
    return desiredPrewarningActive;
  }

  /*----------  Picture / picture in waiting state specific  ----------*/
  getImageSrc(): string {
    let devicePicture = "noPicture.jpg";

    if (this.isDeviceOnWaitingState) {
      devicePicture = this.device.targetPicture || "noPicture.jpg";
    } else {
      devicePicture = this.device.actualPicture || "noPicture.jpg";
    }

    const displayType = this.device.displayType || { width: 48, height: 48 };

    return `${WEP_MOCK_URL}/img/${displayType.width}x${displayType.height}/${devicePicture.replaceAll(".bmp", ".gif")}`;
  }

  getAllTextForTheImage(picture: string): string {
    return picture?.replaceAll(".gif", "").replaceAll(".bmp", "");
  }

  /**
   *
   * Check if image is stau or Stgefahr
   * @param picture
   * @return Boolean
   */
  isPictureStauOrStgefahr(picture: string): boolean {
    const options = ["Stau.bmp", "Stgefahr.bmp"];
    return options.includes(picture);
  }

  getImageNameOfConnectionStrength(asu: number) {
    return getConnectionStrengthImageName(asu || 0).toString();
  }
}
