
/**
 * Scematic view for the main project
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { MainProjectSection, OpenInformationModal } from "@/model/MainProject/MainProjectSection";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ActionMethod, mapActions, mapGetters, mapMutations, MutationMethod } from "vuex";
import SingleBoardInformationModal from "@/components/project/SingleProject/Schematic/InformationModal/SingleBoardInformationModal.vue";
import SchematicViewHeader from "@/components/project/SingleProject/Schematic/SchematicViewHeader.vue";
import StartAndEnd from "@/components/project/SingleProject/Schematic/StartAndEndSchematic.vue";
import MainprojectSingleDeviceView from "@/components/mainProject/MainprojectSingleDeviceView.vue";
import MainProjectTabs from "@/components/mainProject/MainProjectTabs.vue";
import Project from "@/model/Project";

import UserAccess from "@/model/User/UserAccess";
import mainProjectSectionClass from "@/model/MainProject/MainProjectSectionClass";
import CameraSectionImageModal from "@/components/project/SingleProject/Schematic/Camera/CameraSectionImageModal.vue";

@Component({
  name: "MainProjectSchematicView",
  components: {
    SchematicViewHeader,
    StartAndEnd,
    MainProjectTabs,
    MainprojectSingleDeviceView,
    SingleBoardInformationModal,
    CameraSectionImageModal
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("MainProject", {
      GETTER_MAIN_DIRECTIONS: "GETTER_MAIN_DIRECTIONS",
      GET_MODAL_OPEN_INFORMATION: "GET_MODAL_OPEN_INFORMATION",
      GET_MODAL_CAMERA_OPEN_INFORMATION: "GET_MODAL_CAMERA_OPEN_INFORMATION"
    })
  },
  methods: {
    ...mapActions("projects", {
      GetProjectDetailsFromBackEnd: "DetailsOfProject"
    }),
    ...mapMutations("MainProject", {
      RESET_MODAL: "SET_CURRENT_MODAL_OPEN"
    }),
    ...mapMutations("projects", {
      RESET_CAMERA_PICTURE: "ResetCameraSectionLatestPicture"
    }),

    ...mapActions("MainProject", {
      ACTION_SET_OPEN_MODAL_INFORMATION: "ACTION_SET_OPEN_MODAL_INFORMATION"
    }),
    ...mapMutations("projects", {
      ResetProject: "ResetProject"
    })
  }
})
export default class MainProjectSchematicView extends Vue {
  readonly getUserAccess!: UserAccess;
  readonly GETTER_MAIN_DIRECTIONS!: Map<String, Array<MainProjectSection>>;
  readonly GET_MODAL_OPEN_INFORMATION!: OpenInformationModal;
  readonly GET_MODAL_CAMERA_OPEN_INFORMATION!: OpenInformationModal;

  RESET_CAMERA_PICTURE!: Function;

  private ResetProject!: ActionMethod;
  private ACTION_SET_OPEN_MODAL_INFORMATION!: ActionMethod;
  private RESET_MODAL!: MutationMethod;

  private GetProjectDetailsFromBackEnd!: ActionMethod;
  /*----------  Props  ----------*/
  @Prop({ type: Object, required: true }) CURRENT_MAIN_PROJECT!: Project;

  resetProject() {
    this.ResetProject();
    this.RESET_MODAL();
  }

  async HandleOpenInformationModal(openModal: OpenInformationModal) {
    this.ACTION_SET_OPEN_MODAL_INFORMATION(openModal);
    await this.GetProjectDetailsFromBackEnd(openModal.projectId);
    this.$bvModal.show("openDisplayModalDetails");
  }

  isSectionVisible(section: MainProjectSection) {
    const s = new mainProjectSectionClass(section);
    return !s.isNullPoint && (s.isVisible || this.getUserAccess.Access.EDIT.project.sectionListTab.visibleSection);
  }
}
