
/**
 * Metasdata Tab for the main project view and speedfunnel
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import VueI18n from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";
import UpdateProjectForm from "@/components/project/SingleProject/UpdateProjectForm.vue";

@Component({
  name: "MainProjectMetaDataTab",
  components: {
    UpdateProjectForm
  }
})
export default class MainProjectTabs extends Vue {
  $t!: VueI18n["t"];
}
