
/**
 * Main Project section list
 * display all the section from sub projects that are part of the main project
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { MainProjectSection } from "@/model/MainProject/MainProjectSection";
import UserAccess from "@/model/User/UserAccess";
import { Component, Watch, Vue } from "vue-property-decorator";
import { mapGetters, mapActions, ActionMethod } from "vuex";
import dragable from "vuedraggable";
import VueI18n from "vue-i18n";
import mainProjectSectionClass from "@/model/MainProject/MainProjectSectionClass";
import TableHeaderSorting from "@/components/shared/TableHeaderSorting.vue";

@Component({
  name: "MainProjectSectionListTab",
  computed: {
    ...mapGetters("MainProject", {
      GETTER_MAIN_DIRECTIONS: "GETTER_MAIN_DIRECTIONS"
    }),
    ...mapGetters(["getUserAccess"])
  },
  methods: {
    ...mapActions("projects", {
      UPDATE_SECTION_FROM_MAIN_PROJECT: "UpdateSectionFromMainProject"
    }),
    ...mapActions("MainProject", {
      ACTION_GET_MAIN_PROJECT: "ACTION_GET_MAIN_PROJECT",
      ACTION_SORT_SECTIONS_BY_POSITION: "ACTION_SORT_SECTIONS_BY_POSITION"
    })
  },
  components: {
    dragable,
    TableHeaderSorting
  }
})
export default class MainProjectSectionListTab extends Vue {
  $t!: VueI18n["t"];
  readonly GETTER_MAIN_DIRECTIONS!: Map<String, Array<MainProjectSection>>;
  private UPDATE_SECTION_FROM_MAIN_PROJECT!: ActionMethod;
  private ACTION_GET_MAIN_PROJECT!: ActionMethod;
  private ACTION_SORT_SECTIONS_BY_POSITION!: ActionMethod;

  readonly getUserAccess!: UserAccess;
  isLoading = false;
  /*----------  Props  ----------*/

  fields: Array<string> = ["sort", "id", "type", "position", "description", "projectname", "actions"];

  allDirectionsLocal: Record<string, MainProjectSection[]> = {};

  drag = false;
  sortingDefault = "ASC";

  get dragOptions() {
    return {
      animation: 150,
      group: "description",
      disabled: false,
      ghostClass: "ghost",
      handle: ".handle",
      filter: ".notSortableItem"
    };
  }

  @Watch("GETTER_MAIN_DIRECTIONS", { immediate: true })
  HandleUpdateGetterMainSections() {
    this.GETTER_MAIN_DIRECTIONS.forEach((value: MainProjectSection[], key: String): void => {
      this.allDirectionsLocal[key as string] = value;
    });
  }

  haveSections(direction: any) {
    return this.GETTER_MAIN_DIRECTIONS.get(direction)?.length;
  }

  async HandleUpdateVisibilityState(section: MainProjectSection) {
    section.visible = !section.visible;
    const sectionToUpdate = new mainProjectSectionClass(section).getFormattedSectionInfo();

    this.isLoading = true;
    try {
      await this.UPDATE_SECTION_FROM_MAIN_PROJECT({
        sectionToUpdate,
        MainProjectId: this.$route.params.id
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async UpdateAllSectionOrdinalNumber(allSectionInDirection: MainProjectSection[]) {
    try {
      this.isLoading = true;
      let promises = allSectionInDirection.map(async (section, ordinalNumber) => {
        section.mainProjectOrdinal = ordinalNumber;
        let sectionToUpdate = new mainProjectSectionClass(section).getFormattedSectionInfo();
        await this.UPDATE_SECTION_FROM_MAIN_PROJECT({
          sectionToUpdate,
          MainProjectId: this.$route.params.id,
          reloadMainProject: false
        });
      });
      await Promise.all(promises);
      await this.ACTION_GET_MAIN_PROJECT(this.$route.params.id);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async HandleDragEnd(direction: String) {
    const allSectionInDirection = this.allDirectionsLocal[direction as string];
    if (allSectionInDirection?.length) {
      this.UpdateAllSectionOrdinalNumber(allSectionInDirection);
    }
  }

  isSectionVisible(section: MainProjectSection) {
    const sec = new mainProjectSectionClass(section);
    return !sec.isNullPoint && (sec.isVisible || this.getUserAccess.Access.EDIT.project.sectionListTab.visibleSection);
  }

  async handleSortSectionsByPosition({ direction, toggleDirection }: { direction: string; toggleDirection: string }) {
    try {
      this.isLoading = true;
      this.sortingDefault = toggleDirection;
      const projectId = this.$route.params.id;
      await this.ACTION_SORT_SECTIONS_BY_POSITION({ projectId, direction, toggleDirection });
      await this.ACTION_GET_MAIN_PROJECT(this.$route.params.id);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }
}
