
/**
 * Schematic view for the  camera view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { CameraSectionDetails, MainProjectSectionDeviceView } from "@/model/MainProject/MainProjectSection";
import { Component, Mixins } from "vue-property-decorator";
import SchematicDeviceDetails from "./MainProjectSchematicDeviceDetails.vue";
import DeviceStatus from "@/mixins/main/Device";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import { mapActions } from "vuex";
import { getConnectionStrengthImageName } from "@/utils/DeviceHelper";

@Component({
  name: "SchematicCameraDevice",
  methods: {
    ...mapActions("MainProject", {
      ACTION_SET_OPEN_MODAL_CAMERA: "ACTION_SET_OPEN_MODAL_CAMERA"
    })
  },
  components: {
    SchematicDeviceDetails
  }
})
export default class SchematicCameraDevice extends Mixins(DeviceStatus, KmFormatted) {
  // Vuex set camera section details
  // eslint-disable-next-line no-unused-vars
  private ACTION_SET_OPEN_MODAL_CAMERA!: (D: CameraSectionDetails) => void;

  get device(): MainProjectSectionDeviceView {
    if (this.devicePosition === "deviceOne" && this.sec.direction === "DEFAULT") {
      return this.sec.deviceTwo;
    } else if (this.devicePosition === "deviceOne" && this.sec.direction === "OPPOSITE") {
      return this.sec.deviceOne;
    } else if (this.devicePosition === "deviceTwo" && this.sec.direction === "OPPOSITE") {
      return this.sec.deviceTwo;
    } else {
      return this.sec.deviceOne;
    }
  }

  getImageNameOfConnectionStrength(asu: number) {
    return getConnectionStrengthImageName(asu || 0).toString();
  }

  /*----------  Methods  ----------*/
  HandleOpenCameraModal() {
    this.$bvModal.show("cameraSectionImage");
    this.ACTION_SET_OPEN_MODAL_CAMERA({ id: this.sec.id });
  }
}
