
/**
 * Schematic view for the measure section
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { MainProjectSectionDeviceView } from "@/model/MainProject/MainProjectSection";
import { Component, Mixins } from "vue-property-decorator";
import SchematicDeviceDetails from "./MainProjectSchematicDeviceDetails.vue";
import DeviceStatus from "@/mixins/main/Device";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import { getConnectionStrengthImageName } from "@/utils/DeviceHelper";

@Component({
  name: "SchematicMeasureDevice",
  components: {
    SchematicDeviceDetails
  }
})
export default class SchematicMeasureDevice extends Mixins(DeviceStatus, KmFormatted) {
  get device(): MainProjectSectionDeviceView {
    const isTop =
      (this.sec.direction === "OPPOSITE" && !this.sec.radarOnLeftSide) ||
      (this.sec.direction === "DEFAULT" && this.sec.radarOnLeftSide);

    const devices = {
      deviceOne: isTop ? this.sec.deviceOne : this.sec.deviceTwo,
      deviceTwo: isTop ? this.sec.deviceTwo : this.sec.deviceOne
    };
    return devices[this.devicePosition];
  }

  getImageNameOfConnectionStrength(asu: number) {
    return getConnectionStrengthImageName(asu || 0).toString();
  }
}
