
/**
 * All the device in all the subProjects for an specific main project view
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import {
  DeviceInDeviceList,
  DeviceInDeviceListBase,
  MainProjectSection,
  MainProjectSectionDeviceView,
  OpenInformationModal
} from "@/model/MainProject/MainProjectSection";
import UserAccess from "@/model/User/UserAccess";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapActions, ActionMethod } from "vuex";

@Component({
  name: "MainProjectDeviceListTab",
  computed: {
    ...mapGetters("MainProject", {
      GETTER_MAIN_DIRECTIONS: "GETTER_MAIN_DIRECTIONS"
    }),
    ...mapGetters(["getUserAccess"])
  },
  methods: {
    ...mapActions("projects", {
      GetProjectDetailsFromBackEnd: "DetailsOfProject"
    }),
    ...mapActions("MainProject", {
      ACTION_SET_OPEN_MODAL_INFORMATION: "ACTION_SET_OPEN_MODAL_INFORMATION"
    })
  }
})
export default class MainProjectDeviceListTab extends Vue {
  readonly GETTER_MAIN_DIRECTIONS!: Map<String, Array<MainProjectSection>>;
  readonly getUserAccess!: UserAccess;

  private ACTION_SET_OPEN_MODAL_INFORMATION!: ActionMethod;
  private GetProjectDetailsFromBackEnd!: ActionMethod;
  /*----------  Props  ----------*/
  isLoading = false;
  labelsForDeviceList: Array<string> = [
    "orderedSection",
    "id",
    "position",
    "side",
    "picture",
    "brightness",
    "charging_current",
    "discharging_current",
    "solar",
    "battery",
    "state"
  ];

  translateTableHeaderLabel(label: string) {
    return this.$t(`project.tableLabels.projectDeviceListLabels.${label}`);
  }

  formatDeviceInformation(item: MainProjectSectionDeviceView, opt: DeviceInDeviceListBase): DeviceInDeviceList {
    return {
      mainboardId: item.id,
      id: opt.id,
      kilometrage: opt.kilometrage,
      picture: item.actualPicture,
      side: opt.side,
      batteryTerminalCurrent: item.batteryVoltage,
      brightness: item.brightness,
      chargingCurrent: item.charging,
      dischargingCurrent: item.discharging,
      solarArray: item.solarArray,
      status: item.status,
      projectType: opt.projectType,
      projectId: opt.projectId,
      position: opt.position,
      trafficReportEnabled: opt.trafficReportEnabled,
      mainboardUrl: item.mainboardUrl
    };
  }

  getFormattedDevices(direction: String): Array<DeviceInDeviceList> {
    const currentDirectionDevices: DeviceInDeviceList[] = [];

    this.GETTER_MAIN_DIRECTIONS.get(direction)
      ?.filter((section) => section.visible || this.getUserAccess.Access.EDIT.project.sectionListTab.visibleSection)
      .forEach((section: MainProjectSection) => {
        const options: DeviceInDeviceListBase = {
          kilometrage: section.kilometer,
          id: section.id,
          side: "left",
          projectId: section.project.id,
          position: "deviceOne",
          trafficReportEnabled: section.project.trafficReport,
          projectType: {
            isVWA: section.project.vwa,
            isCWU: section.project.congestionWarning,
            isRZP: section.project.roadzipper,
            isFPU: section.project.freeProgrammable,
            isSCENE: section.project.sceneBased,
            isVSU: section.project.variableSign,
            isMq3Aq32SW: section.project.mq3Aq32SW,
            isMq4Aq42SWDEGES: section.project.mq4Aq42SWDEGES,
            isMq3Aq32023: section.project.mq3Aq32023,
            isMq4Aq42SW: section.project.mq4Aq42SW,
            isMq5Aq52SW: section.project.mq5Aq52SW,
            isCwuCascaded: section.project.cwuCascaded,
            isMq1Aq12SW: section.project.mq1Aq12SW
          }
        };
        if (section.deviceOne.id) {
          currentDirectionDevices.push(this.formatDeviceInformation(section.deviceOne, options));
        }
        if (section.deviceTwo.id) {
          options.side = "right";
          options.position = "deviceTwo";
          currentDirectionDevices.push(this.formatDeviceInformation(section.deviceTwo, options));
        }
      });

    return currentDirectionDevices;
  }

  async HandleOpenInformationModal(item: DeviceInDeviceList) {
    const option: OpenInformationModal = {
      projectId: item.projectId,
      sectionId: item.id,
      trafficReport: item.trafficReportEnabled,
      boardPosition: item.position,
      projectType: item.projectType
    };
    this.ACTION_SET_OPEN_MODAL_INFORMATION(option);
    await this.GetProjectDetailsFromBackEnd(option.projectId);
    this.$bvModal.show("openDisplayModalDetails");
  }
}
